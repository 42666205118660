<template>
  <v-card 
    :color="elem.color" 
    :key="elem.id" 
    :style="elem.style"
    :flat="elem.flat"
    :elevation="elem.elevation"
    v-on:contextmenu.prevent="handleContextMenu(elem)"
  >
    <component :is="elem.subcomponent" :elem="elem">
      <div v-for="(item, index) in elem.items" :key="index">
        <component v-show="!item.invisible || item.invisible=='false'"
          :is="item.component"
          :elem="item"
          :id="item.id"
          :ref="item.id"
          :key="item.id"
          :class="item.class"
          :style="item.style"
          :color="item.color"
          :height="item.height"
          :width="item.width"
          :size="item.size"
          :float="item.float"
          :src="item.src"
          :contain="item.contain"
          :label="item.label"
          :type="item.type"
          :max="item.max"
          :min="item.min"
          :step="item.step"
          :name="item.name"
          :readonly="item.readonly"
          :items="item.items"
          v-on:click="handleClick(item)"
          v-on:change="handleChanged(item)"
          v-on:deleteLine="deleteLine(index, item)"
          v-model="item.value"
        >{{translateContent(item)}}</component>
      </div>
    </component>
  </v-card>
</template>

<script>
var defaultMethods = {
  handleClick: function (item) {
    if (window.WsClient.proto_root_ === undefined) {
      window.console.log("protobuf is not ready");
      return;
    }

    var _ui_event;
    switch (item.id) {
      default:
        _ui_event = {
          id: item.id,
        };
        window.WsClient.PacketOut("Generic.Client.Clicked", _ui_event);
        break;
    }
  },
  handleChanged: function (item) {
    if (window.WsClient.proto_root_ === undefined) {
      window.console.log("protobuf is not ready");
      return;
    }
    // var _ui_event;
    switch (item.id) {
      default:
        break;
    }
  },
  translateContent: function(item) {
    const DATETIME_RULE = /^\d\d\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])[T ](0[0-9]|1[0-9]|2[0-3]):([0-9]|[0-5][0-9]):([0-9]|[0-5][0-9])($|Z$| [+-](0[0-9]|1[0-2])\d\d$)/;
    var _content = item.content;
    if (/^\w*([Dd]ate)(_?)([Tt]ime)\w*$/.test(item.id)) {
      _content = '';
      item.content.split('#').forEach(element => {
        let validTime = element.match(DATETIME_RULE);
        if (validTime) {
          _content += new Date(element.substr(0,10) + 'T' + element.substr(11,8) + 'Z').toLocaleString();
        }
        else {
          _content += element;
        }
      });
    }
    else if (/^\w*([Tt]ime)\w*$/.test(item.id)) {
      _content = '';
      item.content.split('#').forEach(element => {
        let validTime = element.match(DATETIME_RULE);
        if (validTime) {
          _content += new Date(element.substr(0,10) + 'T' + element.substr(11,8) + 'Z').toLocaleTimeString('en-GB');
        }
        else {
          _content += element;
        }
      });
    }
    else if (/^\w*([Dd]ate)\w*$/.test(item.id)) {
      _content = '';
      item.content.split('#').forEach(element => {
      let validTime = element.match(DATETIME_RULE);
        if (validTime) {
          let _datetime = new Date(element.substr(0,10) + 'T' + element.substr(11,8) + 'Z');
          const [year, month, day] = [_datetime.getFullYear(), _datetime.getMonth() + 1, _datetime.getDate()];
          _content += `${year}/${month}/${day}`;
        }
        else {
          _content += element;
        }
      });
    }
    else if (/^\w*([Nn]umber)(_?)([Dd]ouble)\w*$/.test(item.id)) {
      _content = '';
      item.content.split('#').forEach(element => {
        let validNumber = element.match(/^-?\d+\.?\d*$/);
        if (validNumber) {
          _content += (Math.round(parseFloat(element) * 100) / 100).toFixed(2);
        }
        else {
          _content += element;
        }
      });
    }
    else if (/^\w*([Nn]umber)(_?)([Ii]nteger)\w*$/.test(item.id)) {
      _content = '';
      item.content.split('#').forEach(element => {
        let validNumber = element.match(/^-?\d+\.?\d*$/);
        if (validNumber) {
          _content += Math.round(parseFloat(element));
        }
        else {
          _content += element;
        }
      });
    }
    else if (/^\w*([Dd]iscount|[Pp]ercent)\w*$/.test(item.id)) {
      _content = '';
      item.content.split('#').forEach(element => {
        let validNumber = element.match(/^-?\d+\.?\d*$/);
        if (validNumber) {
          _content += (Math.round(parseFloat(element) * 10000) / 100).toFixed(2) + '%';
        }
        else {
          _content += element;
        }
      });
    }
    return _content;
  },
  handleContextMenu: function(item) {
    if (this.elem.items.length > 0) {
      this.showMenu = !this.showMenu;
      this.$emit('contextmenu', item);
    }
    else {
      this.$emit('click', item);
    }
  }
};

import { VBtn, VImg, VTextField, VIcon, VSelect } from "vuetify/lib";
import Section from "@/components/Section/Section.vue";
import GridSection from "@/components/Section/GridSection.vue";
import DatePicker from "@/components/DatePicker/DatePicker.vue";
import DataTable from "@/components/DataTable/DataTable.vue";
import Editable_DataTable from "@/components/DataTable/Editable_DataTable.vue";
import YesNoWithDescription from "@/components/Question/YesNoWithDescription.vue";
// import Dialog from "@/components/Dialog/Dialog.vue";
import CardCarousel from "@/components/Carousel/CardCarousel.vue";
import RichText from "@/components/Text/RichText.vue";
import IconButton from "@/components/Button/IconButton.vue";
import deleteSlider from '@/components/Action/deleteTemplate.vue' 
export default {
  components: {
    VBtn,
    VImg,
    VTextField,
    VIcon,
    VSelect,
    Section,
    GridSection,
    DatePicker,
    DataTable,
    Editable_DataTable,
    YesNoWithDescription,
    // Dialog,
    CardCarousel,
    RichText,
    IconButton,
    deleteSlider,
  },
  props: {
    elem: Object
  },
  data: () => ({
    value: ""
  }),
  methods: defaultMethods,
  mounted() {
    if (this.$root.$children[0].methodList !== null && this.$root.$children[0].methodList.length > 0) {
      this.$root.$children[0].methodList.forEach(function (item) {
        if (item.component === "ListCard") {
          this[item.functionName] = new Function("item", item.functionValue).bind(this);
        }
      }, this);
    }
  }
};
</script>

<style scoped>
.txt{
  overflow:auto;
}
.txt-line-clamp2 {
  text-overflow:ellipsis;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;overflow:hidden;
}
</style>