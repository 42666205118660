import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import '@mdi/font/css/materialdesignicons.css';

Vue.config.productionTip = false


function legacyIsMobileCheck() {
  window.console.log('detect with legacy userAgent')
  return window.navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry|IEMobile)/);
}
function isMobile() {
  const uaDataIsMobile = window.navigator.userAgentData?.mobile
  const isMobile = typeof uaDataIsMobile === 'boolean'
    ? uaDataIsMobile
    : legacyIsMobileCheck(window.navigator.userAgent);
  window.console.log('uaDataIsMobile: ' + uaDataIsMobile + ', isMobile: ' + isMobile);
  return isMobile;
}
function isBrowser(){
  return window.navigator.userAgent.match(/(Chrome|Safari|Chromium|Firefox|OPR|Opera|Trident)/);
}

window.console.log('window.navigator.userAgent: ' + window.navigator.userAgent);

if (isMobile() && !isBrowser()) {
  document.addEventListener("deviceready", () => {
    new Vue({
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  });
} else {
  // window.deviceId = "00000000-0000-0000-0000-000000000000";
  new Vue({
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}