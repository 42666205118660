<template>
  <v-container dense>
    <v-row style="text-align:left;margin:0% 2%;" v-if="elem.cols !== undefined && elem.cols > 0" >
      <v-col v-for="(item, i) in elem.items" :key="i" :cols="elem.cols" style="padding:0px;">
        <ListCard :elem="item" :style="item.style"></ListCard>
      </v-col>
    </v-row>
    <v-row style="text-align:left;margin:0% 18px 0px 6px;" v-else >
      <v-col v-for="(item, i) in elem.items" :key="i" xs="12" sm="6" md="4" lg="3" style="padding:0px;">
        <ListCard :elem="item" :style="item.style"></ListCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
var defaultMethods = {
  handleClick: function (item) {
    if (window.WsClient.proto_root_ === undefined) {
      window.console.log("protobuf is not ready");
      return;
    }

    var _ui_event;
    switch (item.id) {
      default:
        _ui_event = {
          id: item.id,
        };
        window.WsClient.PacketOut("Generic.Client.Clicked", _ui_event);
        break;
    }
  }
};

import ListCard from "@/components/Card/ListCard.vue";
export default {
  name: "CardList",
  components: { ListCard },
  props: {
    elem: Object
  },
  data: () => ({
  }),
  methods: defaultMethods,
  mounted() {
    if (this.$root.$children[0].methodList !== null && this.$root.$children[0].methodList.length > 0) {
      this.$root.$children[0].methodList.forEach(function (item) {
        if (item.component === "CardList") {
          this[item.functionName] = new Function("item", item.functionValue).bind(this);
        }
      }, this);
    }
  }
};
</script>