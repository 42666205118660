<template>
  <v-carousel 
    :cycle="elem.cycle"
    :height="elem.height"
    :interval="!elem.interval?6000:elem.interval"
    :show-arrows="!elem.hideArrows || elem.hideArrows=='false'"
    :show-arrows-on-hover="elem.showArrowsOnHover"
    :hide-delimiters="elem.hideDelimiters"
    :hide-delimiter-background="elem.hideDelimiterBackground"
    light
  >
    <v-carousel-item v-for="(item, i) in elem.items" :key="i">
      <component
        :is="item.component" v-if="!item.invisible || item.invisible=='false'"
        :elem="item"
        :id="item.id"
        :ref="item.id"
        :key="item.id"
        :class="item.class"
        :style="item.style"
        :color="item.color"
        :height="item.height"
        :width="item.width"
        :float="item.float"
        :src="item.src"
        :contain="item.contain"
        :label="item.label"
        :type="item.type"
        :max="item.max"
        :min="item.min"
        :step="item.step"
        :name="item.name"
        :readonly="item.readonly"
        :items="item.items"
        v-on:click="handleClick(item)"
        v-model="item.value"
      >{{item.content}}</component>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import { VBtn, VImg, VTextField, VIcon, VSelect } from "vuetify/lib";
import hRefImg from "@/components/Image/hRefImg.vue";
export default {
  name: "Carousel_multi_items",
  components: {
    VBtn,
    VImg,
    VTextField,
    VIcon,
    VSelect,
    hRefImg
  },
  props: {
    elem: Object
  },

  data: () => ({})
};
</script>
