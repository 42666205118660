<template>
  <v-app :style="background">
    <template v-for="item in body">
      <div :key="item.id">
        <component v-if="item.component !=='Dialog' || item.id === 'app_waiting_note' || item.component ==='OK' || !item.invisible || item.invisible=='false'" v-show="!item.invisible || item.invisible=='false'"
          :is="item.component"
          :elem="item"
          :id="item.id"
          :ref="item.id"
          :class="item.class"
          :style="item.style"
          :color="item.color"
          :height="item.height"
          :width="item.width"
          :float="item.float"
          :src="item.src"
          :contain="item.contain"
          :label="item.label"
          :type="item.type"
          :max="item.max"
          :min="item.min"
          :step="item.step"
          :value="item.value"
          :name="item.name"
          :readonly="item.readonly"
          :openOnClick="item.openOnClick"
          :children="item.children"
          :items="item.items"
          :activatable="item.activatable"
          :returnObject="item.returnObject"
          v-on:click="handleClick(item)"
          @update:active="on_item_selected"
        >{{item.content}}</component>
      </div>
    </template>
  </v-app>
</template>

<script>
import { VBtn, VImg, VTextField } from "vuetify/lib";
import Container from "@/components/Section/Container.vue";
import List from "@/components/List/List.vue";
import Card from "@/components/Card/Card.vue";
import BottomNavigation from "@/components/Navigation/BottomNavigation.vue";
import Footer from "@/components/Footer/Footer.vue";
import YesNo from "@/components/Question/YesNo.vue";
import OK from "@/components/Question/OK.vue";
import Dialog from "@/components/Dialog/Dialog.vue";
import DataTable from "@/components/DataTable/DataTable.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import Loading from "@/Loading.json";
import VeriCode from "@/components/VeriCode/VeriCode.vue";
export default {
  name: "App",
  components: {
    VBtn,
    VImg,
    VTextField,
    List,
    Card,
    BottomNavigation,
    Footer,
    YesNo,
    OK,
    Dialog,
    DataTable,
    Tabs,
    VeriCode,
    Container,
  },

  data: () => ({
    //body: [],
    body: Loading.layout,
    background:
      Loading.background.style +
      (Loading.background.src?
      "background-image:url(" +
      require("@/" + Loading.background.src) +
      ");":''),
    userData: null,
    methodList: null,
    params: null,
  }),
  methods: {
    handleClick: function(item) {
      window.console.log(item.id + " clicked");

      var _ui_event = {
        id: item.id
      };
      window.WsClient.PacketOut("Generic.Client.Clicked", _ui_event);
      switch (item.id) {
        case "show_version":
          if (this.body[2].content !== "" && window.cordova !== undefined) {
            var _self = this;
            window.cordova.getAppVersion.getAppName().then(function(appName) {
              // My App Name
              //console.log("App Name", appName);
              var _appName = appName;
              window.cordova.getAppVersion
                .getVersionNumber()
                .then(function(versionNumber) {
                  // 1.0.0
                  //window.console.log("version number", versionNumber);
                  var _dialog_content = JSON.parse(_self.body[2].content);
                  _dialog_content.question = _appName + " v." + versionNumber; //navigator.appVersion;
                  _self.body[2].content = JSON.stringify(_dialog_content);
                  _self.$refs.app_version_info[0].dialog = true;
                });
            });
          }
          break;
        default:
          break;
      }
    },
    on_item_selected: function(object) {
      if (object) {
        window.console.log("id:" + object[0].id + ", name:" + object[0].name);
      }
    },
    replaceKeyword: function(element) {
      if (element.src != undefined && element.src != "" && element.src.match(/^@\/.*$/)) {
        element.src = require("@/" + element.src.substr(2));
      }
      if (element.src2 != undefined && element.src2 != "" && element.src2.match(/^@\/.*$/)) {
        element.src2 = require("@/" + element.src2.substr(2));
      }
      switch (element.component)
      {
        case 'v-checkbox': {
          element.value = (element.value && element.value !== "false" && element.value !== "0");
          break;
        }
        case 'v-rating': {
          element.value = parseFloat(element.value);
          break;
        }
        default: {
          if (element.value[0]==='{' && element.value[element.value.length - 1]==='}'
                ||element.value[0]==='[' && element.value[element.value.length - 1]===']') {
            try {
              element.value = JSON.parse(element.value)
            }
            catch {
              window.console.log('parse error: ' + element.value);
            }
          }
          break;
        }
      }
      if (element.items != undefined) {
        element.items.forEach(element => {
          this.replaceKeyword(element);
        });
      }
    },
    findElement: function(targetList,element) {
      const UUID_RULE = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;
      let _target = null;
      targetList.every(child => {
        if (child.id == element.id) {
          _target = targetList;
          return false;
        } else if (child.id.match(UUID_RULE) && child.items) {
          _target = this.findElement(child.items,element);
          return (!_target);
        }
        return true;
      });
      return _target;
    },
    replaceElement: function(targetList,element) {
      if (element.id) {
        if (element.component) {
          targetList.forEach((element2,index) => {
            if (element2.id === element.id) {
              targetList[index] = element;
            }
          });
        } else if (element.items != undefined) {
          let _target = this.findElement(targetList,element);
          if (_target) {
            element.items.forEach(child => {
              this.replaceElement(_target.find(x => x.id === element.id).items, child);
            },this);
          }
        }
      }
    },
    eventHandler: function(name, object) {
      window.console.log(name);
      switch (name) {
        case "VuetifyKits.Layout":
          object.layout.forEach(element => {
            this.replaceKeyword(element);
          },this);
          this.body = object.layout;
          if (object.background !== undefined && object.background !== null) {
            this.background = object.background.style;
            if (
              object.background.src != undefined &&
              object.background.src != ""
            ) {
              this.background +=
                "background-image:url(" +
                require("@/" + object.background.src) +
                ");";
            }
          } else {
            this.background = "";
          }
          if (object.userData !== undefined && object.userData !== "") {
            this.userData = JSON.parse(object.userData);
            Object.entries(this.userData).forEach(
              ([key, value]) => {
                switch (key) {
                  case 'eval':
                    setTimeout(() => {
                      window.eval(value);
                    }, 100);
                    break;
                  default:
                    break;
                }
              }
            );
          }
          else {
            this.userData = null;
          }
          // if (object.methodList !== undefined && object.methodList !== "") {
          //   this.methodList = JSON.parse(object.methodList);
          // }
          this.methodList = object.methodList;
          break;
        case "VuetifyKits.PartialLayout":
          object.layout.forEach(element => {
            this.replaceKeyword(element);
          },this);
          object.layout.forEach(element => {
            this.replaceElement(this.body, element);
          },this);
          this.$forceUpdate();
          break;
        case "Generic.Client.ConfigComplete":
          var _ui_event = {
            account: localStorage.getItem("account"),
            deviceId: window.deviceId,
            sessionId: localStorage.getItem("sessionId"),
            host: window.location.protocol + "//" + window.location.host,
            timeOffset: - new Date().getTimezoneOffset() * 60,
            resetId: this.params.get('reset_id'),
            registrationId: this.params.get('registration_id'),
            loginType: this.params.get('admin')?'admin':'',
            autoLogin: true,
          };
          window.console.log('checkCode=' + this.params.get('id'));
          window.WsClient.PacketOut("Generic.Client.Login", _ui_event);
          break;
        case "Generic.Client.Login.Result":
          if (object.sessionId != undefined && object.sessionId !== "") {
            window.console.log(
              "login successfully with session id: " + object.sessionId
            );
            if (object.account) {
              localStorage.setItem("account", object.account);
            }
            if (object.sessionId) {
              localStorage.setItem("sessionId", object.sessionId);
            }

            var _keepalive_interval = window.setInterval(() => {
              if (
                typeof window.WsClient.socket !== "undefined" &&
                window.WsClient.socket !== null
              ) {
                if (
                  window.WsClient.socket.readyState !==
                  window.WsClient.socket.OPEN
                ) {
                  window.clearInterval(_keepalive_interval);
                  window.console.log("connection closed");
                  this.body = Loading.layout;
                  this.background = 
                    Loading.background.style +
                    (Loading.background.src?
                    "background-image:url(" +
                    require("@/" + Loading.background.src) +
                    ");":'');
                } else {
                  var _ui_event = {
                    id: ""
                  };
                  window.WsClient.PacketOut("Generic.Client.Clicked", _ui_event);
                }
              }
            }, 5000);

          } else {
            window.console.log("login failed with message " + object.message);
          }
          break;
        case "Generic.Client.ShowMessage":
          if (object.formId !== undefined && object.formId !== "") {
            for (var element of this.$children[0].$children) {
              if (
                element.elem !== undefined &&
                element.elem !== null &&
                element.elem.id === object.formId
              ) {
                element.elem.content = object.content;
                element.dialog = true;
                element.isFormValid = true;
                break;
              }
            }
          }
          break;
        case "Generic.Client.ExitApp":
          navigator.app.exitApp();
          break;
        case "Generic.Client.Logout": {
          localStorage.removeItem("account");
          localStorage.removeItem("sessionId");
          window.location.reload();
          break;
        }
        case "Generic.Client.Notification":
          var _notification_content = {
            title: object.title,
            text: this.translateContent(object.content),
            trigger: { at: new Date(new Date(object.time.substr(0, 19).replace(' ', 'T') + '+08:00').getTime()  + (object.remind  * 1000))},
            foreground: true
          };
          window.cordova.plugins.notification.local.schedule(_notification_content);
          break;
        default:
          break;
      }
    },
    todo: function() {
      var _interval_id = window.setInterval(() => {
        if (
          typeof window.WsClient !== "undefined" &&
          window.WsClient !== null &&
          typeof window.WsClient.WebEventProto != "undefined" &&
          window.WsClient.WebEventProto != null
        ) {
          window.clearInterval(_interval_id);
          window.console.log("add App.eventHandler");
          window.WsClient.addPacketInHandler(this.eventHandler);
          this.connect();
        }
      }, 1000);
    },
    connect: function() {
      if (
        window.WsClient.socket === undefined ||
        window.WsClient.socket === null
      ) {
        window.WsClient.connectSocket();
      }
      // window.setTimeout(() => {
      var _interval_id = window.setInterval(() => {
        if (
          typeof window.WsClient.socket !== "undefined" &&
          window.WsClient.socket !== null
        ) {
          if (
            window.WsClient.socket.readyState !== window.WsClient.socket.OPEN
          ) {
            window.WsClient.connectSocket();
          } else {
            window.clearInterval(_interval_id);
            window.console.log("Connect successfully");
          }
        }
      }, 5000);
    },
    back_btn_clicked() {
      if (
        window.WsClient.socket === null ||
        window.WsClient.socket.readyState !== window.WsClient.socket.OPEN
      ) {
        navigator.app.exitApp();
      } else {
        var _ui_event = {
          id: "btn_back"
        };
        window.WsClient.PacketOut("Generic.Client.Clicked", _ui_event);
      }
    },
    translateContent: function(item) {
      var _content = item.content;
      switch (item.id) {
        case 'date_time': {
          let validTime = item.value.match(/^\d\d\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])[T ](0[0-9]|1[0-9]|2[0-3]):([0-9]|[0-5][0-9]):([0-9]|[0-5][0-9])($|Z$| [+-](0[1-9]|1[0-2])\d\d$)/);
          if (validTime) {
            _content = new Date(item.value.substr(0,10) + 'T' + item.value.substr(11,8) + 'Z').toLocaleString();
            item.value = _content;
          }
          break;
        }
        case 'time': {
          let validTime = item.value.match(/^\d\d\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])[T ](0[0-9]|1[0-9]|2[0-3]):([0-9]|[0-5][0-9]):([0-9]|[0-5][0-9])($|Z$| [+-](0[1-9]|1[0-2])\d\d$)/);
          if (validTime) {
            _content = new Date(item.value.substr(0,10) + 'T' + item.value.substr(11,8) + 'Z').toLocaleTimeString('en-GB');
            item.value = _content;
          }
          break;
        }
        case 'date': {
          _content = '';
          item.content.split('#').forEach(element => {
            let validTime = element.match(/^\d\d\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])[T ](0[0-9]|1[0-9]|2[0-3]):([0-9]|[0-5][0-9]):([0-9]|[0-5][0-9])($|Z$| [+-](0[1-9]|1[0-2])\d\d$)/);
            if (validTime) {
              let _datetime = new Date(element.substr(0,10) + 'T' + element.substr(11,8) + 'Z');
              const [year, month, day] = [_datetime.getFullYear(), _datetime.getMonth() + 1, _datetime.getDate()];
              _content += `${year}-${month}-${day}`;
            }
            else {
              _content += element;
            }
          });
          break;
        }
        default:
          break;
      }
      return _content;
    },
    getParameters: function() {
      const queryString = window.location.search;
      this.params = new URLSearchParams(queryString);
    }
  },
  mounted: function() {
    this.todo();
    document.addEventListener("backbutton", this.back_btn_clicked, false);
    this.getParameters();
  },
  beforeDestroy() {
    document.removeEventListener("backbutton", this.back_btn_clicked);
  }
};
</script>

<style src="./assets/styles/App.css"/>
