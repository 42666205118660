<template>
  <v-tabs v-if="elem.customizedHover" v-model="tab" :vertical="elem.vertical" :style="elem.style" :color="elem.color" background-color="transparent" grow>
    <v-tab v-for="(item,i) in computedItems" :key="i" :ref="item.id" v-on:click="handleClick(item)" style="width:20vw;padding:0px;" class="no-print">
        {{ item.label }}
        <v-icon>{{ item.icon }}</v-icon>
        <v-hover>
          <v-img slot-scope="{ hover }" v-if="hover" :src="item.src2">
          </v-img>
          <v-img v-else :src="item.src">
          </v-img>
        </v-hover>
    </v-tab>
    <v-tabs-items v-model="tab" :touchless="true">
      <v-tab-item v-for="(item,i) in computedItems" :key="i" >
        <TabCard :elem="item" :style="item.style">
        </TabCard>
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
  <v-tabs v-else v-model="tab" :vertical="elem.vertical" :style="elem.style" :color="elem.color" background-color="transparent" grow>
    <v-tab v-for="(item,i) in computedItems" :key="i" :ref="item.id" v-on:click="handleClick(item)" class="no-print">
        {{ item.label }}
        <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
        <v-img v-if="item.src" :src="item.src"/>
    </v-tab>
    <v-tabs-items v-model="tab" :touchless="true">
      <v-tab-item v-for="(item,i) in computedItems" :key="i" >
        <TabCard :elem="item" :style="item.style">
        </TabCard>
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import TabCard from "@/components/Card/TabCard.vue";
export default {
  name: "Tabs",
  components: {
    TabCard
  },
  props: {
    elem: Object
  },
  data() {
    return {
      tab: null,
      items: []
    };
  },
  methods: {
    handleClick: function(item) {
      if (window.WsClient.proto_root_ === undefined) {
        window.console.log("protobuf is not ready");
        return;
      }
      var _ui_event = {
        id: item.id
      };
      window.WsClient.PacketOut(
        "Generic.Client.Clicked",
        _ui_event
      );
    }
  },
  computed: {
    computedItems: function() {
      let _ret = [];
      this.elem.items.forEach(element => {
        if (!element.invisible) {
          _ret.push(element);
        }
      });
      return _ret;
    }
  },
};
</script>