<template>
  <v-card
    :style="elem.style"
  >
    <v-card-title v-if="elem.label || !elem.dataTable.noSearch">
      {{elem.label}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        style="margin:0px;padding:0px;"
        label="Search"
        single-line
        hide-details
        v-if="!elem.dataTable.noSearch"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      v-model="selectedTask"
      :headers="computedHeaders"
      :items="computedRecordset"
      :hide-default-header="elem.dataTable.noHeader"
      :hide-default-footer="elem.dataTable.noFooter"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 30, 40, 50, 1000]
      }"
      :items-per-page="elem.dataTable.pageSize"
      item-key="subject_id"
      class="elevation-1;"
      @click:row="handleClickRow($event)"
      :search="search"
    >
      <template #item.url="{ value }">
          <a target="_blank" :href="value">
            {{ value }}
          </a>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
var defaultMethods = {
// eslint-disable-next-line no-unused-vars
  handleClickRow(item) {
  },
  handleClick(item) {
    // eslint-disable-next-line no-unused-vars
    const {row,col} = item;
    if (!col) {
      return;
    }
    switch (col.value)
    {
      default:
        break;
    }
  },
  handleChanged(item) {
    if (window.WsClient.proto_root_ === undefined) {
      window.console.log("protobuf is not ready");
      return;
    }
    // eslint-disable-next-line no-unused-vars
    const {event,row,col} = item;
    switch(this.elem.id) {
      default:
        break;
    }
  },
  // eslint-disable-next-line no-unused-vars
  handleUserData: function(item) {
  },
};

export default {
  props: {
    elem: Object
  },
  data: () => ({
    selectedTask: [],
    search: null,
  }),
  computed: {
    computedHeaders() {
      var headers = [];
      this.elem.dataTable.headers.forEach((element) => {
        var _obj = element;
        _obj.sortable = (element.sortable==false?false:true);
        headers.push(_obj);
      });
      return headers;
    },
    computedRecordset() {
      if (this.elem.dataTable.recordset === "") return [];
      else { 
        const DATETIME_RULE = /^\d\d\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])[T ](0[0-9]|1[0-9]|2[0-3]):([0-9]|[0-5][0-9]):([0-9]|[0-5][0-9])($|Z$| [+-](0[0-9]|1[0-2])\d\d$)/;
        const BOOLEAN_RULE = /^(?:(1|y(?:es)?|t(?:rue)?|on)|(0|n(?:o)?|f(?:alse)?|off))$/i;
        const DECIMAL_NUMBER_RULE = /^\d+\.\d+$/;
        const NUMBER_RULE = /^\d+$/;
        var _recordset = JSON.parse(this.elem.dataTable.recordset);
        _recordset.forEach((element) => {
          Object.entries(
            element
          ).forEach(([key, value]) => {
            if (typeof value != 'string')
              return;
            if (value.match(DATETIME_RULE)) {
              if (/^\w*([Dd]ate)(_?)([Tt]ime)\w*$/.test(key)) {
                element[key+"_orginal"] = new Date(
                  element[key].substr(0, 19).replace(' ', 'T') + "Z"
                );
                element[key] = element[key+"_orginal"].toLocaleString();
              }
              else if (/^\w*([D|d]ate)\w*$/.test(key)) {
                element[key+"_orginal"] = new Date(
                  element[key].substr(0, 19).replace(' ', 'T') + "Z"
                );
                element[key] = element[key+"_orginal"].toLocaleDateString();
              }
              else if (/^\w*([Tt]ime)\w*$/.test(key)) {
                element[key+"_orginal"] = new Date(
                  element[key].substr(0, 19).replace(' ', 'T') + "Z"
                );
                element[key] = element[key+"_orginal"].toLocaleTimeString('en-GB');
              }
            }
            else if (value.match(BOOLEAN_RULE) && /^(Is|is|Has|has)\w*$/.test(key)) {
              element[key] = /^(?:1|y(?:es)?|t(?:rue)?|on)$/i.test(value);
            }
            else if (value.match(DECIMAL_NUMBER_RULE) && /^\w*([Dd]iscount|[Pp]ercent)\w*$/.test(key)) {
              element[key] = element[key] * 100 + '%';
            }
            else if (value.match(DECIMAL_NUMBER_RULE) && /^\w*([Ff]loat|[Dd]ouble)\w*$/.test(key)) {
              element[key] = Math.round(element[key]*100)/100;
            }
            else if (value.match(DECIMAL_NUMBER_RULE) && /^\w*([Ii]nteger)\w*$/.test(key)) {
              element[key] = Math.round(element[key]);
            }
            else if (value.match(NUMBER_RULE) && /^\w*([Pp]ad[Ii]nteger8)\w*$/.test(key)) {
              element[key] = element[key].padStart(8,0);
            }
            else if (/^\w*(JSON|json)2([Ll]ist)\w*$/.test(key)) {
              let _content = '';
              element[key].split('#').forEach(element => {
                try {
                  let _listInJSON = JSON.parse(element);
                  Object.entries(_listInJSON).forEach(([key, value]) => {
                    if (value)
                      _content += key + ' ';
                  });
                }
                catch {
                  _content += element;
                }
              });
              element[key] = _content;
            }
          });
        });

        return _recordset;
      }
    }
  },
  methods: defaultMethods,
  mounted() {
    if (this.$root.$children[0].methodList !== null && this.$root.$children[0].methodList.length > 0) {
      this.$root.$children[0].methodList.forEach(function (item) {
        if (item.component === "DataTable") {
          this[item.functionName] = new Function("item", item.functionValue).bind(this);
        }
      }, this);
    }
    if (this.$root.$children[0].userData) {
      this.handleUserData(this.$root.$children[0].userData);
    }
  }
};
</script>

<style>
.v-data-table th,
.v-data-table td {
  font-size: 16px;
}
</style>