<template>
  <v-card style="elem.style">
    <v-list flat style="background-color: transparent;border-color: transparent;">
      <v-subheader :class="elem.class">{{elem.label}}</v-subheader>
      <v-list-item-group v-model="selection" :color="elem.color">
        <template v-for="(item, i) in elem.items">
          <v-list-item :key="item.id" v-show="!item.invisible || item.invisible=='false'" v-on:click="handleClick(item)">
            <template v-slot:default="{ /*active, toggle*/ }">
              <v-list-item-icon style="margin-right:10px;min-width:0px;">
                <v-icon v-text="item.icon" :size="item.size" :color="item.color"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.content" :class="item.class"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
          <v-divider
            v-if="divided & i + 1 < elem.items.length"
            :key="i"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
var defaultMethods = {
  handleClick: function (item) {
    if (window.WsClient.proto_root_ === undefined) {
      window.console.log("protobuf is not ready");
      return;
    }

    var _ui_event;
    switch (item.id) {
      default:
        _ui_event = {
          id: item.id,
        };
        window.WsClient.PacketOut("Generic.Client.Clicked", _ui_event);
        break;
    }
  }
};

//import Section from "@/components/Section/Section.vue";
export default {
  components: { },
  props: {
    elem: Object
  },
  data: () => ({
    selection: 0,
    divided: false
  }),
  methods: defaultMethods,
  mounted() {
    if (this.$root.$children[0].methodList !== null && this.$root.$children[0].methodList.length > 0) {
      this.$root.$children[0].methodList.forEach(function (item) {
        if (item.component === "List") {
          this[item.functionName] = new Function("item", item.functionValue).bind(this);
        }
      }, this);
    }
  }
};
</script>