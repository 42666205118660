<template>
  <div fluid id="post-area" class="post-container">
    <v-row no-gutters class="mx-0 px-0">
      <v-col
        cols="12"
        class="pa-0"
        tabindex="0"
        id="postContainer"
        :class="{
          'light-scroll transparent lighten-4': true,
          'post-responses-float': true
        }"
        ref="postContainer"
      >
        <Section id="post-scroll" :elem="elem" subcomponent="div" :items="elem.items" :style="elem.style + ';height:' + elem.height"/>
      </v-col>
    </v-row>

    <postInput v-if="!elem.post || !elem.post.noInput"
      :toggleButton="showAudioInput"
      :passUserInput="userInput"
      :handleInputFocus="handleFocus"
      :sendParams="sendParams"
      :mustSend="mustSend"
      :key="postInputComponentKey"
      :class="{
          'light-scroll green lighten-4': true,
      }"
      @reset="resetpostInputDirections"
      @scroll="scrollToBottom"
    ></postInput>
  </div>
</template>

<script>
import { debounce } from "@/utils/utils";

import PostInput from "@/components/Post/PostInput.vue";
import Section from "@/components/Section/Section.vue";

export default {
  props: {  
    elem: Object
  },
  components: {
    PostInput,
    Section,
  },

  data() {
    return {
      showAudioInput: false,
      userInput: "",
      handleFocus: false,
      sendParams: "",
      mustSend: false,
      showLeopardDialog: false,
      interval: {},
      mustScroll: true,
      oldDialogLength: 0,
      showDate: false,
      showTime: false,
      showFeedback: false,
      audioInFocus: false,
      date: "",
      isScrolling: false,
      postInputComponentKey: "55555", // so important for IE11 performance!!

      showpostLoading: false,
      showLivepostProcessing: false,
      progressBar: false,
    };
  },
  watch: {
    date: function(newDate) {
      if (newDate !== "") {
        // this.updateInputBox(dayjs(newDate).format("D MMMM YYYY"));
      }
    },
    dialog: function(newDialog) {
      if (newDialog.length !== this.oldDialogLength) {
        this.mustScroll = true;
        this.oldDialogLength = newDialog.length;
      }
    },
  },
  beforeRouteLeave(from, to, next) {
    this.$emit("closeMenu");
    next();
  },
  computed: {
    dialog() {
        return this.getLatestDialogHistory ? this.getLatestDialogHistory : [];
    }
  },
  updated: debounce(function() {
    try {
      // if (this.mustScroll) {
        this.mustScroll = false;
        this.scrollToBottom();
      // }
    } catch (e) {
      // logger.debug(e);
      // do nothing
    }
  }, 200),
  mounted() {
    this.scrollToBottom();
  },
  beforeDestroy() {},
  methods: {
    resetpostInputDirections() {
      this.handleFocus = false;
      this.userInput = "";
      this.mustSend = false;
      this.sendParams = "";
      this.showDate = false;
      this.showTime = false;
      this.date = "";
      this.postInputComponentKey += 1;
    },
    updateInputBox(userInput) {
      this.userInput = userInput;
    },
    triggerSend(delay = 0) {
      setTimeout(() => {
        this.mustSend = true;
      }, delay);
    },
    swapInputButton() {
      this.showAudioInput = !this.showAudioInput;
    },
    debounceScroll() {
      if (this.isScrolling) {
        return;
      }
      // logger.debug("Scroll to bottom");
      this.isScrolling = true;
      let scrollToElement = document.getElementById("post-scroll");
      const options = {
        duration: 1200,
        offset: -50,
        easing: "easeInQuad",
        container: "#post-scroll"
      };
      try {
        if (scrollToElement) {
          this.$vuetify.goTo(9999, options);
        } else {
          this.isScrolling = false;
        }
      } catch {
        this.isScrolling = false;
      }
      setTimeout(() => {
        this.isScrolling = false;
      }, 1250);
    },
    scrollToBottom() {
      debounce(this.debounceScroll(), 2000, false);
      //this.debounceScroll();
    },
    onHtmlClick(event) {
      // Find the closest anchor to the target.
      const anchor = event.target.closest("a");
      if (!anchor) return;

      // Check to make sure this is from our v-html because
      // we don't want to handle clicks from other things in
      // the Vue
      if (!anchor.classList.contains("sendInput") && !anchor.classList.contains("openInIframe")) {
        return; // basically treat like a normal link
      } else if (anchor.classList.contains("openInIframe")) {
        // logger.debug(`Open Link in IFRAME`);
        // open in iframe
        event.stopPropagation();
        event.preventDefault();
        this.$store.commit("UPDATE_FRAME_URL", anchor.getAttribute("href"));
      } else {
        // send input
        event.stopPropagation();
        event.preventDefault();
        if (anchor.getAttribute("data-input")) {
          // logger.debug(`Update input box with data-input attribute of link`);
          this.userInput = anchor.getAttribute("data-input");
        } else {
          // logger.debug(`Update input box with text of link`);
          this.userInput = anchor.innerText;
        }
        this.sendParams = "&isClick=true";
        this.triggerSend(200);
      }
    }
  }
};
</script>
<style scoped>
.loading-ball {
  width: 360px;
}

#post-scroll {
  scrollbar-width: thin;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  outline: 0;
}

#post-scroll:focus {
  outline: 0;
  box-shadow: inset 0 0 0 1px rgba(17, 18, 25, 0.2) !important;
}

.container {
  padding: 0 !important;
}

</style>
<style>
div.upload-btn {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

label.upload-btn {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

button.v-expansion-panel-header:active {
  border-style: none !important;
}

button.v-expansion-panel-header:focus {
  background: unset;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

div#post-area p {
  margin-bottom: 2px;
}

div.chat-footer {
  top: calc(100vh - 90px);
  position: absolute;
  width: 100vw;
}

div.chat-footer .v-input__slot {
  margin-top: 8px !important;
  padding-right: 0px !important;
  background: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

div.chat-footer .v-text-field__details {
  position: absolute;
  margin-top: 45px;
}

div.post-container .v-expansion-panel-header {
  cursor: unset !important;
  user-select: text !important;
}

v-expansion-panel-header div.post-container .v-expansion-panel:not(:first-child)::after {
  border-top: none !important;
}

.v-expansion-panel:not(:first-child)::after {
  border-top: none !important;
}

div.post-container .v-expansion-panel-header--mousedown {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

div.post-container .v-expansion-panel::before {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.v-toolbar__title:not(:first-child) {
  margin-left: 12px !important;
}

.v-toolbar__title {
  font-size: 17px !important;
  white-space: unset !important;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  /* .post-card {
    font-size: 1.1em;
    font-weight: 400;
    padding: 8px;
    margin-top: 4px;
    width: 260px;
    line-height: 1.2em;
    display: table;
  } */
}

.post-card {
  display: table;
  width: fit-content;
  margin-top: 4px;
  padding: 8px !important;
  font-weight: 400;
  line-height: 1.4em;
}

.post-card-left {
  padding-bottom: 3px !important;
  -moz-border-radius: 3px 13px 13px 13px !important;
  -webkit-border-radius: 3px 13px 13px 13px !important;
  border-radius: 3px 13px 13px 13px !important;
}

.post-card-right {
  margin-left: auto !important;
  -moz-border-radius: 13px 3px 13px 13px !important;
  -webkit-border-radius: 13px 3px 13px 13px !important;
  border-radius: 13px 3px 13px 13px !important;
}

div.options-list a.v-list__tile--link {
  height: inherit !important;
  cursor: pointer;
}

.post-responses-float {
  overflow-x: hidden;
  overflow-y: auto;
  /* min-height: calc(100vh - 900px); */
}

.post-responses-float-mobile {
  height: calc(var(--vh, 1vh) * 80 - 0px) !important;
}

.post-responses {
  overflow-x: hidden;
  overflow-y: auto;
  /* height: calc(100vh - 10px); */
  /* min-height: calc(100vh - 10px); */
  /* max-height: calc(100vh - 10px); */
}

.post-container {
  overflow-x: hide !important;
  width: 100%;
}

.post-container-inner {
  overflow-x: hide !important;
  -webkit-box-shadow: 0 2px 20px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 20px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
}


@media only screen and (max-height: 480px) {
  .post-responses,
  .post-responses-float {
    /* min-height: calc(100vh - 130px); */
  }

  .post-responses-float-mobile {
    /* height: calc(var(--vh, 1vh) * 100 - 130px); */
    /* min-height: calc(var(--vh, 1vh) * 100 - 130px); */
  }
}

@media only screen and (max-width: 480px) {
  .v-footer,
  .post-container,
  .loading-ball {
    width: 100vw !important;
  }

  .post-responses,
  .post-responses-float {
    /* min-height: calc(100vh - 130px); */
  }

  .post-responses-float-mobile {
    /* height: calc(var(--vh, 1vh) * 100 - 130px); */
    /* min-height: calc(var(--vh, 1vh) * 100 - 130px); */
  }
}

.post-card-right-tail
{
  margin-left: auto !important;
  -moz-border-radius: 10px 10px 10px 10px !important;
  -webkit-border-radius: 10px 10px 10px 10px !important;
  border-radius: 10px 10px 10px 10px !important;
}

.post-card-right-tail:after {
    content: '';
    top: 6px;
    right: -25px;
    position: absolute;
    border: 0px solid;
    display: block;
    width: 25px;
    height: 10px;
    background-color: transparent;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    box-shadow: -21px 9px 0px 8px rgba(165, 219, 255, 0.7);
}

.post-card-left-tail {
  padding-bottom: 3px !important;
  -moz-border-radius: 10px 10px 10px 10px !important;
  -webkit-border-radius: 10px 10px 10px 10px !important;
  border-radius: 10px 10px 10px 10px !important;
}

.post-card-left-tail:after {
  content: '';
  top: 6px;
  left: -25px;
  position: absolute;
  border: 0px solid;
  display: block;
  width: 25px;
  height: 10px;
  background-color: transparent;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  box-shadow: 21px 9px 0px 8px rgb(154, 205, 50, 0.7);
}
</style>
