<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat color="white">
          <v-btn v-if="!isMobile" outlined class="mr-4" color="grey darken-2" @click="setToday" style="margin-left:-16px;">今天</v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev" style="margin-left:-16px;width:min-content;">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next" style="width:min-content;">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <v-toolbar-title style="font-size:medium;">{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on }">
              <v-btn outlined color="grey darken-2" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>{{typeToLabel["day"]}}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>{{typeToLabel["week"]}}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>{{typeToLabel["month"]}}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>{{typeToLabel["4day"]}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="computedEvents"
          :event-color="getEventColor"
          :now="today"
          :type="type"
          :locale="elem.locale"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="handleChanged"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
<!-- 
              <v-btn icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon color="red">mdi-delete-alert-outline</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
 -->
            </v-toolbar>
            <v-card-text>
              <span v-html="selectedEvent.project?selectedEvent.project:selectedEvent.description"></span>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="secondary" @click="selectedOpen = false">{{title_close}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
var defaultMethods = {
  viewDay({ date }) {
    this.focus = date;
    this.type = "day";
  },
  getEventColor(event) {
    return event.color;
  },
  setToday() {
    this.focus = this.today;
  },
  prev() {
    this.$refs.calendar.prev();
  },
  next() {
    this.$refs.calendar.next();
  },
  showEvent({ nativeEvent, event }) {
    const open = () => {
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      setTimeout(() => (this.selectedOpen = true), 10);
    };

    if (this.selectedOpen) {
      this.selectedOpen = false;
      setTimeout(open, 10);
    } else {
      open();
    }

    nativeEvent.stopPropagation();
  },
  handleChanged(item) {
    // const events = []

    // const min = new Date(`${start.date}T00:00:00`)
    // const max = new Date(`${end.date}T23:59:59`)
    // const days = (max.getTime() - min.getTime()) / 86400000
    // const eventCount = this.rnd(days, days + 20)

    // for (let i = 0; i < eventCount; i++) {
    //   const allDay = this.rnd(0, 3) === 0
    //   const firstTimestamp = this.rnd(min.getTime(), max.getTime())
    //   const first = new Date(firstTimestamp - (firstTimestamp % 900000))
    //   const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
    //   const second = new Date(first.getTime() + secondTimestamp)

    //   events.push({
    //     name: this.names[this.rnd(0, this.names.length - 1)],
    //     start: this.formatDate(first, !allDay),
    //     end: this.formatDate(second, !allDay),
    //     color: this.colors[this.rnd(0, this.colors.length - 1)],
    //   })
    // }
    const { start, end } = item;
    this.start = start;
    this.end = end;
    // this.events = events
    var _ui_event = {
      id: this.elem.id,
      content: JSON.stringify({
        startTime: new Date(`${start.date} 00:00:00`).toISOString().substr(0,19).replace('T', ' '),
        endTime: new Date(`${end.date} 23:59:59`).toISOString().substr(0,19).replace('T', ' '),
      })
    };
    window.WsClient.PacketOut("Generic.Client.Clicked", _ui_event);
  },
  nth(/*d*/) {
    return this.typeToLabel["day"];
    // return d > 3 && d < 21
    //   ? "th"
    //   : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
  },
  rnd(a, b) {
    return Math.floor((b - a + 1) * Math.random()) + a;
  },
  formatDate(a, withTime) {
    return withTime
      ? `${a.getFullYear()}-${a.getMonth() +
          1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
      : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
  },
  legacyIsMobileCheck() {
    window.console.log('detect with legacy userAgent')
    return window.navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry|IEMobile)/);
  },
  checkMobile() {
    const uaDataIsMobile = window.navigator.userAgentData?.mobile
    const isMobile = typeof uaDataIsMobile === 'boolean'
      ? uaDataIsMobile
      : this.legacyIsMobileCheck(window.navigator.userAgent);
    window.console.log('uaDataIsMobile: ' + uaDataIsMobile + ', isMobile: ' + isMobile);
    return isMobile;
  },
}
export default {
  props: {
    elem: Object
  },
  data: () => ({
    focus: "",
    type: "month",
    typeToLabel: {
      year: "年",
      month: "月",
      week: "週",
      day: "日",
      "4day": "四日"
    },
    title_close: "關閉",
    start: null,
    end: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    //events: [],
    today: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
  }),
  computed: {
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = start.year + this.typeToLabel['year'];
      const endYear = end.year;
      const suffixYear = start.year === endYear ? "" : endYear + this.typeToLabel['year'];

      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);

      switch (this.type) {
        case "month":
          return `${startYear}${startMonth}`;
        case "week":
        case "4day":
          return `${startYear}${startMonth}${startDay} - ${suffixYear}${suffixMonth}${endDay}`;
        case "day":
          return `${startYear}${startMonth}${startDay}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long"
      });
    },
    computedEvents() {
      if (this.elem.userData === "") return [];
      else {
        Date.prototype.toLocalISOString = function () {
            function pad(number) { return ('' + number).padStart(2, '0') }
            return `${this.getFullYear()}-${pad(this.getMonth() + 1)}-${pad(this.getDate())}T${pad(this.getHours())}:${pad(this.getMinutes())}:${pad(this.getSeconds())}`
        }
        var _event_list = JSON.parse(this.elem.userData);
        _event_list.forEach((element) => {
          if (element.start !== undefined && element.start !== '') {
            element.start = new Date(
              element.start.replace(' ', 'T')
            ).toLocalISOString();
          }
          if (element.end !== undefined && element.end !== '') {
            element.end = new Date(
              element.end.replace(' ', 'T')
            ).toLocalISOString();
          }
        });

        return _event_list;
      }
    },
    isMobile() {
      return this.checkMobile();
    },
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: defaultMethods,
}
</script>