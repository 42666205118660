<template>
  <v-expansion-panels
    :key="elem.id"
    :id="elem.id"
    :ref="elem.id"
    :style="elem.style"
    focusable
  >
    <v-expansion-panel 
      v-for="(item, i) in elem.items"
      :id="'panel_' + item.id"
      :ref="'panel_' + item.id"
      :key="'panel_' + i"
      v-on:change="handleChanged(item)"
    >
      <v-expansion-panel-header :style="item.style">
        <template v-slot:default="{ open }">
          <v-row no-gutters>
            <v-col :cols="item.cols">
              {{ item.label }}
            </v-col>
            <v-col
              :cols="12-item.cols"
              class="text--secondary"
            >
              <v-fade-transition leave-absolute>
                <span v-if="open" key="0" style="font-size:small;color:red;float:right;">
                  {{ message }}
                </span>
                <span v-else key="1" style="font-size:small;color:red;float:right;">
                  {{ item.content }}
                </span>
              </v-fade-transition>
            </v-col>
          </v-row>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
          <component :is="item.component" 
            :elem="item" 
            :id="item.id" 
            :ref="item.id" 
            :key="item.key"
            :items="item.items"
          />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
var defaultMethods = {
  // eslint-disable-next-line no-unused-vars
  handleChanged: function (item) {
    if (window.WsClient.proto_root_ === undefined) {
      window.console.log("protobuf is not ready");
      return;
    }
    switch (item.id) {
      default:
        break;
    }
  },
};

import Section from "@/components/Section/Section.vue";
import Section_L2 from "@/components/Section/Section_L2.vue";
export default {
  props: {
    elem: Object
  },
  components: {
    Section,
    Section_L2,
  },
  data: () => ({
    itemList_: {},
    message: null,
  }),
  methods: defaultMethods,
  mounted() {
    if (this.$root.$children[0].methodList !== null && this.$root.$children[0].methodList.length > 0) {
      this.$root.$children[0].methodList.forEach(function (item) {
        if (item.component === "ExpansionList") {
          this[item.functionName] = new Function("item", item.functionValue).bind(this);
        }
      }, this);
    }
  }
};
</script>

<style>
div {
    white-space: none;
}
</style>