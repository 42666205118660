<template>
  <v-card
    :style="elem.style"
    v-if="!elem.invisible"
  >
    <v-card-title 
      v-if="elem.label || !elem.dataTable.noSearch"
      style="font-size:x-large;padding-left:0px;"
    >
      {{elem.label}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        style="margin:0px;padding:0px;"
        label="Search"
        single-line
        hide-details
        v-if="!elem.dataTable.noSearch"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="computedHeaders"
      :items="computedRecordset"
      :hide-default-header="elem.dataTable.noHeader"
      :hide-default-footer="elem.dataTable.noFooter"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 30, 40, 50, 1000]
      }"
      :items-per-page="elem.dataTable.pageSize"
      :disable-pagination="!elem.dataTable.pageSize"
      item-key="class"
      class="elevation-1;"
      :height="elem.height"
      :search="search"
      :sort-by="elem.dataTable.sortBy"
      :sort-desc="elem.dataTable.sortDesc"
      :mobile-breakpoint="elem.dataTable.noRotation?0:600"
      fixed-header
    >
      <template v-slot:item="{ isMobile, item, headers }">
        <div v-if="isMobile && !elem.dataTable.noRotation">
          <tr v-for="(col,colIndex) in headers" 
            :key="colIndex" 
            :style="'line-height:2.0;vertical-align:middle;width:100%;display:table;' + retrieve_background(item.style)"
            @click="handleClickRow(item)">
            <td><span :style="col.style +'display:table-cell;text-align:left;'">{{col.text}}</span></td>
            <td 
              style= 'display:table-cell;text-align:-webkit-right;'
              :class="col.class"
            >
              <div
                v-if="item.text_list!==undefined && item.text_list.length>0 && item.text_list.indexOf(colIndex)>=0 || col.subcomponent=='div'"
                :style="item.style"
                @click="handleClick({'row':item,'col':col})"
              >{{item[col.value]}}</div>
              <v-select
                v-else-if="item.select_list!==undefined && item.select_list.length>0 && item.select_list.indexOf(colIndex)>=0 || col.subcomponent=='v-select'"
                :items="item[col.value].items"
                :value="item[col.value].value"
                :style="item.style"
                :multiple="item.multiple"
                v-model="item[col.value].value"
                @change="handleChanged({'event':$event,'row':item,'col':col})"
              />
              <v-combobox
                v-else-if="item.combobox_list!==undefined && item.combobox_list.length>0 && item.combobox_list.indexOf(colIndex)>=0 || col.subcomponent=='v-combobox'"
                :items="item[col.value].items"
                :value="item[col.value].value"
                :style="item.style"
                v-model="item[col.value].value"
                @change="handleChanged({'event':$event,'row':item,'col':col})"
              />
              <v-text-field
                v-else-if="item.textfield_list!==undefined && item.textfield_list.length>0 && item.textfield_list.indexOf(colIndex)>=0 || col.subcomponent=='v-text-field'"
                :value="item[col.value]"
                :style="item.style"
                :label="col.label"
                :type="col.type"
                :min="col.min"
                :max="col.max"
                :step="col.step"
                @change="handleChanged({'event':$event,'row':item,'col':col})"
                @click="handleClick({'row':item,'col':col})"
                @input="handleInput({'event':$event,'row':item,'col':col})"
                @keydown.enter="handleEnter({'row':item,'col':col})"
              />
              <v-textarea
                v-else-if="item.textarea_list!==undefined && item.textarea_list.length>0 && item.textarea_list.indexOf(colIndex)>=0 || col.subcomponent=='v-textarea'"
                :value="item[col.value]"
                :style="item.style"
                auto-grow
                @change="handleChanged({'event':$event,'row':item,'col':col})"
              />
              <v-btn
                v-else-if="item.btn_list!==undefined && item.btn_list.length>0 && item.btn_list.indexOf(colIndex)>=0 || col.subcomponent=='v-btn'"
                :style="item.style"
                @click="handleClick({'row':item,'col':col})"
              >{{item[col.value]}}</v-btn>
              <v-checkbox
                v-else-if="item.checkbox_list!==undefined && item.checkbox_list.length>0 && item.checkbox_list.indexOf(colIndex)>=0 || col.subcomponent=='checkbox'"
                v-model="item[col.value]"
                :style="item.style"
                :disabled="col.disabled"
                @change="handleChanged({'event':$event,'row':item,'col':col})"
              >{{item[col.value]}}</v-checkbox>
              <v-icon
                v-else-if="item.icon_list!==undefined && item.icon_list.length>0 && item.icon_list.indexOf(colIndex)>=0 || col.subcomponent=='v-icon'"
                :style="item.style"
                @click="handleClick({'row':item,'col':col})"
              >{{item[col.value]}}</v-icon>
              <img 
                v-else-if="item.img_list!==undefined && item.img_list.length>0 && item.img_list.indexOf(colIndex)>=0 || col.subcomponent=='img'"
                :src="item[col.value]" 
                :style="col.style"
                :alt="col.text"
                @click="handleClick({'row':item,'col':col})"
              />
              <span
                v-if="item.bool_list!==undefined && item.bool_list.length>0 && item.bool_list.indexOf(colIndex)>=0 || col.subcomponent=='bool'"
                :style="item.style"
                @click="handleClick({'row':item,'col':col})"
              >{{translateContent({'row':item,'col':col,'type':'bool_list'})}}</span>
              <Section_L2
                v-if="item.section_list!==undefined && item.section_list.length>0 && item.section_list.indexOf(colIndex)>=0 || col.subcomponent=='Section_L2'"
                :elem="item[col.value]"
                :value="item"
              />
            </td>
          </tr>
          <v-divider style="margin-top:10px;margin-bottom:10px"/>
        </div>
        <tr v-else
          @click="handleClickRow(item)"
          :style=retrieve_background(item.style)
        >
          <td v-for="(col,colIndex) in headers" 
            :key="colIndex"
            :class="col.class"
            :style="col.style"
          >
            <span
              v-if="item.header_list!==undefined && item.header_list.length>0 && item.header_list.indexOf(colIndex)>=0 || col.subcomponent=='header'"
              style="font-weight:bold;"
              @click="handleClick({'row':item,'col':col})"
            >{{item[col.value]}}</span>
            <div
              v-if="item.text_list!==undefined && item.text_list.length>0 && item.text_list.indexOf(colIndex)>=0 || col.subcomponent=='div'"
              :style="item.style"
              @click="handleClick({'row':item,'col':col})"
            >{{item[col.value]}}</div>
            <v-select
              v-else-if="item.select_list!==undefined && item.select_list.length>0 && item.select_list.indexOf(colIndex)>=0 || col.subcomponent=='v-select'"
              :items="item[col.value].items"
              :value="item[col.value].value"
              :style="item.style"
              v-model="item[col.value].value"
              @change="handleChanged({'event':$event,'row':item,'col':col})"
            />
            <v-combobox
              v-else-if="item.combobox_list!==undefined && item.combobox_list.length>0 && item.combobox_list.indexOf(colIndex)>=0 || col.subcomponent=='v-combobox'"
              :items="item[col.value].items"
              :value="item[col.value]"
              :style="item.style"
              v-model="item[col.value].value"
              @change="handleChanged({'event':$event,'row':item,'col':col})"
            />
            <v-text-field
              v-else-if="item.textfield_list!==undefined && item.textfield_list.length>0 && item.textfield_list.indexOf(colIndex)>=0 || col.subcomponent=='v-text-field'"
              :value="item[col.value]"
              :style="item.style"
              :label="col.label"
              :type="col.type"
              :min="col.min"
              :max="col.max"
              :step="col.step"
              @change="handleChanged({'event':$event,'row':item,'col':col})"
              @click="handleClick({'row':item,'col':col})"
              @input="handleInput({'event':$event,'row':item,'col':col})"
              @keydown.enter="handleEnter({'row':item,'col':col})"
            />
            <v-textarea
              v-else-if="item.textarea_list!==undefined && item.textarea_list.length>0 && item.textarea_list.indexOf(colIndex)>=0 || col.subcomponent=='v-textarea'"
              :value="item[col.value]"
              :style="item.style"
              auto-grow
              @change="handleChanged({'event':$event,'row':item,'col':col})"
            />
            <v-btn
              v-else-if="item.btn_list!==undefined && item.btn_list.length>0 && item.btn_list.indexOf(colIndex)>=0 || col.subcomponent=='v-btn'"
              :style="item.style"
              @click="handleClick({'row':item,'col':col})"
            >{{item[col.value]}}</v-btn>
            <v-checkbox
              v-else-if="item.checkbox_list!==undefined && item.checkbox_list.length>0 && item.checkbox_list.indexOf(colIndex)>=0 || col.subcomponent=='v-checkbox'"
              v-model="item[col.value]"
              :style="item.style"
              :disabled="col.disabled"
              @change="handleChanged({'event':$event,'row':item,'col':col})"
            >{{item[col.value]}}</v-checkbox>
            <v-icon
              v-else-if="item.icon_list!==undefined && item.icon_list.length>0 && item.icon_list.indexOf(colIndex)>=0 || col.subcomponent=='v-icon'"
              :style="item.style"
              @click="handleClick({'row':item,'col':col})"
            >{{item[col.value]}}</v-icon>
            <img 
              v-else-if="item.img_list!==undefined && item.img_list.length>0 && item.img_list.indexOf(colIndex)>=0 || col.subcomponent=='img'"
              :src="item[col.value]" 
              :style="col.style"
              :alt="col.text"
              @click="handleClick({'row':item,'col':col})"
            />
            <span
              v-if="item.bool_list!==undefined && item.bool_list.length>0 && item.bool_list.indexOf(colIndex)>=0 || col.subcomponent=='bool'"
              :style="item.style"
              @click="handleClick({'row':item,'col':col})"
            >{{translateContent({'row':item,'col':col,'type':'bool_list'})}}</span>
            <Section_L2
              v-if="item.section_list!==undefined && item.section_list.length>0 && item.section_list.indexOf(colIndex)>=0 || col.subcomponent=='Section_L2'"
              :elem="item[col.value]"
              :value="item"
            />
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { toJSON } from 'cssjson';
var defaultMethods = {
// eslint-disable-next-line no-unused-vars
  handleClickRow(row) {
  },
  handleClick(item) {
    // eslint-disable-next-line no-unused-vars
    const {row,col} = item;
    if (!col) {
      return;
    }
    switch (col.value)
    {
      default:
        break;
    }
  },
  handleChanged(item) {
    if (window.WsClient.proto_root_ === undefined) {
      window.console.log("protobuf is not ready");
      return;
    }
    // eslint-disable-next-line no-unused-vars
    const {event,row,col} = item;
    switch(this.elem.id) {
      default:
        break;
    }
  },
  handleInput(item) {
    if (window.WsClient.proto_root_ === undefined) {
      window.console.log("protobuf is not ready");
      return;
    }
    // eslint-disable-next-line no-unused-vars
    const {event,row,col} = item;
    switch(this.elem.id) {
      default:
        break;
    }
  },
  // eslint-disable-next-line no-unused-vars
  handleUserData: function(item) {
  },
  translateContent: function(item) {
    const DATETIME_RULE = /^\d\d\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])[T| ](0[0-9]|1[0-9]|2[0-3]):([0-9]|[0-5][0-9]):([0-9]|[0-5][0-9])($|Z$| [+-](0[0-9]|1[0-2])\d\d$)/;
    var _content = item.content;
    var _value = item.value;
    const options = { hour12: false };
    if (/^\w*([D|d]ate)(_?)([T|t]ime)\w*$/.test(item.id)) {
      _content = '';
      item.content.split('#').forEach(element => {
        let validTime = element.match(DATETIME_RULE);
        if (validTime) {
          _content += new Date(element.substr(0,10) + 'T' + element.substr(11,8) + 'Z').toLocaleString('zh-TW',options).slice(0,-3);
        }
        else {
          _content += element;
        }
      });
      if (item.value && item.value.includes('#') > 0) {
        _value = '';
        item.value.split('#').forEach(element => {
          let validTime = element.match(DATETIME_RULE);
          if (validTime) {
            _value += new Date(element.substr(0,10) + 'T' + element.substr(11,8) + 'Z').toLocaleString('zh-TW',options).slice(0,-3);
          }
          else {
            _value += element;
          }
        });
        item.value = _value;
      }
    }
    else if (/^\w*([T|t]ime)\w*$/.test(item.id)) {
      _content = '';
      item.content.split('#').forEach(element => {
        let validTime = element.match(DATETIME_RULE);
        if (validTime) {
          _content += new Date(element.substr(0,10) + 'T' + element.substr(11,8) + 'Z').toLocaleTimeString('en-GB').slice(0,-3);
        }
        else {
          _content += element;
        }
      });
      if (item.value && item.value.includes('#') > 0) {
        _value = '';
        item.value.split('#').forEach(element => {
          let validTime = element.match(DATETIME_RULE);
          if (validTime) {
            _value += new Date(element.substr(0,10) + 'T' + element.substr(11,8) + 'Z').toLocaleTimeString('en-GB').slice(0,-3);
          }
          else {
            _value += element;
          }
        });
        item.value = _value;
      }
    }
    else if (/^\w*([D|d]ate)\w*$/.test(item.id)) {
      _content = '';
      item.content.split('#').forEach(element => {
      let validTime = element.match(DATETIME_RULE);
        if (validTime) {
          let _datetime = new Date(element.substr(0,10) + 'T' + element.substr(11,8) + 'Z');
          const [year, month, day] = [_datetime.getFullYear(), _datetime.getMonth() + 1, _datetime.getDate()];
          _content += `${year}/${month}/${day}`;
        }
        else {
          _content += element;
        }
      });
      if (item.value && item.value.includes('#') > 0) {
        _value = '';
        item.value.split('#').forEach(element => {
        let validTime = element.match(DATETIME_RULE);
          if (validTime) {
            let _datetime = new Date(element.substr(0,10) + 'T' + element.substr(11,8) + 'Z');
            const [year, month, day] = [_datetime.getFullYear(), _datetime.getMonth() + 1, _datetime.getDate()];
            _value += `${year}/${month}/${day}`;
          }
          else {
            _value += element;
          }
        });
        item.value = _value;
      }
    }
    else if (/^\w*([N|n]umber)(_?)([D|d]ouble)\w*$/.test(item.id)) {
      _content = '';
      item.content.split('#').forEach(element => {
        let validNumber = element.match(/^-?\d+\.?\d*$/);
        if (validNumber) {
          _content += Math.round(parseFloat(element) * 100) / 100;
        }
        else {
          _content += element;
        }
      });
      if (item.value && item.value.includes('#') > 0) {
        _value = '';
        item.value.split('#').forEach(element => {
          let validNumber = element.match(/^-?\d+\.?\d*$/);
          if (validNumber) {
            _value += Math.round(parseFloat(element) * 100) / 100;
          }
          else {
            _value += element;
          }
        });
        item.value = _value;
      }
    }
    return _content;
  },
  handleEnter: function(item) {
    // eslint-disable-next-line no-unused-vars
    const {row,col} = item;
    if (!col) {
      return;
    }
    switch (col.value)
    {
      default:
        break;
    }
  },
  retrieve_background: function(style)
  {
    const _json_style = toJSON(style);
    return _json_style?'background-color:'+_json_style.attributes['background-color']:''
  }
};

import Section_L2 from "@/components/Section/Section_L2.vue";

export default {
  components: {
    Section_L2,
  },
  props: {
    elem: Object
  },
  data: () => ({
    itemList_:{},
    search: null,
}),
  computed: {
    computedHeaders() {
      this.elem.dataTable.headers.forEach(element => {
        var _obj = element;
        _obj.sortable = element.sortable == false ? false : true;
      });
      return this.elem.dataTable.headers;
    },
    computedRecordset() {
      if (this.elem.dataTable.recordset === "") return [];
      else { 
        const DATETIME_RULE = /^\d\d\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])[T ](0[0-9]|1[0-9]|2[0-3]):([0-9]|[0-5][0-9]):([0-9]|[0-5][0-9])($|Z$| [+-](0[0-9]|1[0-2])\d\d$)/;
        const BOOLEAN_RULE = /^(?:(1|y(?:es)?|t(?:rue)?|on)|(0|n(?:o)?|f(?:alse)?|off))$/i;
        const DECIMAL_NUMBER_RULE = /^\d+\.\d+$/;
        const NUMBER_RULE = /^\d+$/;
        const options = { hour12: false };
        var _recordset = JSON.parse(this.elem.dataTable.recordset);
        _recordset.forEach((element) => {
          Object.entries(
            element
          ).forEach(([key, value]) => {
            if (typeof value != 'string')
              return;
            if (value.match(DATETIME_RULE)) {
              if (/^\w*([Dd]ate)(_?)([Tt]ime)\w*$/.test(key)) {
                  element[key] = new Date(
                    element[key].substr(0, 19).replace(' ', 'T') + "Z"
                  ).toLocaleString('zh-TW',options);
              }
              else if (/^\w*([Dd]ate)\w*$/.test(key)) {
                  element[key] = new Date(
                    element[key].substr(0, 19).replace(' ', 'T') + "Z"
                  ).toLocaleDateString();
              }
              else if (/^\w*([Tt]ime)\w*$/.test(key)) {
                  element[key] = new Date(
                    element[key].substr(0, 19).replace(' ', 'T') + "Z"
                  ).toLocaleTimeString('en-GB').slice(0,-3);
              }
            }
            else if (value.match(BOOLEAN_RULE) && /^(Is|is|Has|has)\w*$/.test(key)) {
              element[key] = /^(?:1|y(?:es)?|t(?:rue)?|on)$/i.test(value);
            }
            else if (value.match(DECIMAL_NUMBER_RULE) && /^\w*([Dd]iscount|[Pp]ercent)\w*$/.test(key)) {
              element[key] = element[key] * 100 + '%';
            }
            else if (value.match(DECIMAL_NUMBER_RULE) && /^\w*([Ff]loat|[Dd]ouble)\w*$/.test(key)) {
              element[key] = (Math.round(element[key]*100)/100).toFixed(2);
            }
            else if (value.match(DECIMAL_NUMBER_RULE) && /^\w*([Ii]nteger)\w*$/.test(key)) {
              element[key] = Math.round(element[key]);
            }
            else if (value.match(NUMBER_RULE) && /^\w*([Pp]ad[Ii]nteger8)\w*$/.test(key)) {
              element[key] = element[key].padStart(8,0);
            }
            else if (/^\w*(JSON|json)2([Ll]ist)\w*$/.test(key)) {
              let _content = '';
              element[key].split('#').forEach(element => {
                try {
                  let _listInJSON = JSON.parse(element);
                  Object.entries(_listInJSON).forEach(([key, value]) => {
                    if (value)
                      _content += key + ' ';
                  });
                }
                catch {
                  _content += element;
                }
              });
              element[key] = _content;
            }
          });
        });

        return _recordset;
      }
    }
  },
  methods: defaultMethods,
  mounted() {
    if (this.$root.$children[0].methodList !== null && this.$root.$children[0].methodList.length > 0) {
      this.$root.$children[0].methodList.forEach(function (item) {
        if (item.component === "Editable_DataTable") {
          this[item.functionName] = new Function("item", item.functionValue).bind(this);
        }
      }, this);
    }
    if (this.$root.$children[0].userData) {
      this.handleUserData(this.$root.$children[0].userData);
    }
  }
}
</script>

<style>
.v-data-table .v-data-table__wrapper table thead tr th,
.v-data-table .v-data-table__wrapper table tbody tr td {
  font-size: 16px;
  padding: 0px 0px;
}
.v-data-table .v-data-table__wrapper table tbody tr .fixed {
    position: sticky !important;
    position: -webkit-sticky !important;
    z-index: 9998;
    background: white;
    left: var(--left,0px);
  }
.v-data-table .v-data-table__wrapper table thead tr .fixed {
    z-index: 9999;
    position: sticky !important;
    position: -webkit-sticky !important;
    background: white;
    left: var(--left,0px);
  }
</style>