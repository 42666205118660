import { render, staticRenderFns } from "./DialogCard.vue?vue&type=template&id=642bb2e3&scoped=true&"
import script from "./DialogCard.vue?vue&type=script&lang=js&"
export * from "./DialogCard.vue?vue&type=script&lang=js&"
import style0 from "./DialogCard.vue?vue&type=style&index=0&id=642bb2e3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "642bb2e3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})
