<template>
  <v-btn
    :fab="elem.fab"
    :icon="(elem.iconized && elem.iconized !== 'false' && elem.iconized !== '0')"
    :class="elem.class"
    :id="elem.id"
    :ref="elem.id"
    :style="elem.style"
    :width="elem.width"
    :height="elem.height"
    :color="elem.color"
    :alt="elem.alt"
    :disabled="elem.disabled"
    v-on:click="handleClick()"
  >
    <v-col cols="12">
      <v-row style="place-content:center;margin-bottom:0px;" width="100%">
        <v-badge v-if="elem.badge && !elem.badge.invisible && elem.badge.content" 
          :color="elem.badge.color" 
          :content="elem.badge.content" 
          :style="elem.badge.style"
          :class="elem.badge.class"
          :bordered="elem.badge.bordered"
          :overlap="elem.badge.overlap"
        >
        </v-badge>
        <v-icon 
          :color="elem.color2" 
          :size="elem.size" 
          style="position:absolute;top:0px;"
        >{{elem.icon}}</v-icon>
      </v-row>
      <v-row style="place-content:center;" width="100%">
        <span class="body-1" :style="'position:absolute;margin-top:calc(' + elem.size + 'px - 12px);'">
          {{elem.label}}
        </span>
      </v-row>
    </v-col>
  </v-btn>
</template>


<script>
export default {
  props: {
    elem: Object
  },
  data: () => ({
  }),
  methods: {
    handleClick: function() {
      this.$emit('click');
    }
  }
};
</script>
