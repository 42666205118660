<template>
  <v-card :color="elem.color" :key="elem.id" style="background-color: transparent;border-color: transparent;">
    <div v-for="(item, i) in elem.items" :key="i">
      <component
        :is="item.component" v-if="!item.invisible || item.invisible=='false'"
        :elem="item"
        :id="item.id"
        :ref="item.id"
        :key="item.id"
        :class="item.class"
        :style="item.style"
        :color="item.color"
        :height="item.height"
        :width="item.width"
        :float="item.float"
        :src="item.src"
        :contain="item.contain"
        :label="item.label"
        :type="item.type"
        :max="item.max"
        :min="item.min"
        :step="item.step"
        :name="item.name"
        :readonly="item.readonly"
        :items="item.items"
        v-on:click="handleClick(item)"
        v-model="value"
      >{{item.content}}</component>
    </div>
  </v-card>
</template>

<script>
var defaultMethods = {
  handleClick: function (item) {
    if (window.WsClient.proto_root_ === undefined) {
      window.console.log("protobuf is not ready");
      return;
    }

    var _ui_event;
    switch (item.id) {
      default:
        _ui_event = {
          id: item.id,
        };
        window.WsClient.PacketOut("LaiCafe.Client.Clicked", _ui_event);
        break;
    }
  }
};

import { VBtn, VImg, VTextField, VIcon, VSelect } from "vuetify/lib";
import Section_L2 from "@/components/Section/Section_L2.vue";
import DatePicker from "@/components/DatePicker/DatePicker.vue";
import DataTable from "@/components/DataTable/DataTable.vue";
import Editable_DataTable from "@/components/DataTable/Editable_DataTable.vue";
//import Dialog from "@/components/Dialog/Dialog.vue";    // recursive import!! Never do this!!
import YesNo from "@/components/Question/YesNo.vue";
import hRefImg from "@/components/Image/hRefImg.vue";
export default {
  components: {
    VBtn,
    VImg,
    VTextField,
    VIcon,
    VSelect,
    Section_L2,
    DatePicker,
    DataTable,
    Editable_DataTable,
//    Dialog,
    YesNo,
    hRefImg
  },
  props: {
    elem: Object
  },
  data: () => ({
    value: ""
  }),
  methods: defaultMethods,
  mounted() {
    if (this.$root.$children[0].methodList !== null && this.$root.$children[0].methodList.length > 0) {
      this.$root.$children[0].methodList.forEach(function (item) {
        if (item.component === "CarouselCard") {
          this[item.functionName] = new Function("item", item.functionValue).bind(this);
        }
      }, this);
    }
  }
};
</script>

<style scoped>
</style>