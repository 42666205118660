<template>
  <v-menu
    v-model="showMenu"
    offset-overflow
    offset-y
    style="max-width: 600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :fab="elem.fab"
        :icon="elem.iconized"
        :class="elem.class"
        :id="elem.id"
        :ref="elem.id"
        :style="elem.style"
        :width="elem.width"
        :height="elem.height"
        :color="elem.color"
        text
        v-bind="attrs"
        v-on="on"
        @click="handleClick(elem)"
      >
        <img 
          :src="elem.src" 
          :alt="elem.alt" 
          :style="{'width':elem.width, 'height':elem.height}"
          v-if="elem.src && !(elem.value==true || elem.value=='true')"
        />
        <img 
          :src="elem.src2" 
          :alt="elem.alt"
          :style="{'width':elem.width, 'height':elem.height}"
          v-if="elem.src2 && (elem.value==true || elem.value=='true')"
        />
      </v-btn>
    </template>

    <v-list
      style="background-color:#f8f8f8;color:black;"
      v-if="elem.items.length>0"
    >
      <v-list-item
        v-for="(item, index) in elem.items"
        :key="index"
        @click="handleClick(item)"
      >
        <v-list-item-title :style="item.style">{{ item.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>


<script>
export default {
  name: "MenuToggleButton",
  props: {
    elem: Object
  },
  data: () => ({
    showMenu: false,
  }),
  methods: {
    handleClick: function(item) {
      this.$emit('click', item);
    }
  }
};
</script>

<style scoped>
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled)
{
  color: #4f4f4f !important;
}
</style>