<template>
  <div>
    <v-btn
      :style="elem.style"
      :color="elem.color"
      :class="elem.class"
      @click="print"
    >
      {{elem.content}}
    </v-btn>
  </div>
</template>

<script>
var defaultMethods = {
  print: async function() {
    if (window.WsClient.proto_root_ === undefined) {
      window.console.log("protobuf is not ready");
      return;
    }

    var _ui_event;
    switch (this.$attrs.id) {
      default:
        _ui_event = {
          id: this.$attrs.id,
        };
        window.WsClient.PacketOut("Generic.Client.Clicked", _ui_event);
        break;
    }

    //Pass the element id here
    const localOptions = {
      styles: [
        // some custom styles to override the global style
        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
        './styles/print.css'
      ]
    };
    await this.$htmlToPaper(this.elem.userData, localOptions);
  }
};
export default {
  props: {
    elem: Object
  },
  data: () => ({
      output: null
  }),
  methods: defaultMethods,
}
</script>
